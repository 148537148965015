(function() {
    'use strict';

    angular.module('uabMoment', []);
})();
(function() {
    'use strict';

    angular.module('uabMoment').filter('millisecondsToDateTime', millisecondsToDateTime);

    function millisecondsToDateTime() {
        return function(milliseconds) {
            var d = new Date(0, 0, 0, 0, 0, 0, 0);

            d.setMilliseconds(milliseconds);

            return d;
        };
    }
})();
(function() {
    'use strict';

    angular.module('uabMoment').controller(
        'MomentController',
        MomentController
    );

    MomentController.$inject = [
        'MomentService'
    ];

    function MomentController(
        MomentService
    ) {
        var MomentController = this;

        MomentController.clear = clear;
        function clear() {
            MomentController.reset();
            MomentService.reset();
        }

        MomentController.reset = reset;
        function reset() {
            MomentController.today = MomentService();

            MomentController.admDtpFormat = MomentService.toAdmDtpFormat(
                MomentController.today
            );

            MomentController.databaseFormat = MomentService.toDatabaseFormat(
                MomentController.today
            );

            MomentController.toDefaultFormat = MomentService.toDatabaseFormat(
                MomentController.today
            );

            MomentController.userFormat = MomentService.toUserFormat(
                MomentController.today
            );
        }

        MomentController.init = init;
        function init() {
            MomentController.reset();
        }

        MomentController.init();
    }
})();

(function() {
    'use strict';

    angular.module('uabMoment').service(
        'MomentService',
        MomentService
    );

    function MomentService() {
        var MomentService = moment;

        MomentService.toAdmDtpFormat = toAdmDtpFormat;
        function toAdmDtpFormat(date) {
            return MomentService(date).utc().format('YYYY/MM/DD hh:mm');
        }

        MomentService.toDefaultFormat = toDefaultFormat;
        function toDefaultFormat(date) {
            return MomentService(date).format('YYYY-MM-DD HH:mm');
        }

        MomentService.toDatabaseFormat = toDatabaseFormat;
        function toDatabaseFormat(date) {
            return MomentService(date).format('YYYY-MM-DDTHH:mm:ss+0000');
        }

        MomentService.toDateOnlyFormat = toDateOnlyFormat;
        function toDateOnlyFormat(date) {
            return MomentService(date).format('YYYY-MM-DD');
        }

        MomentService.toUserFormat = toUserFormat;
        function toUserFormat(date) {
            return MomentService(date).local().format('MMM DD, YYYY hh:mm A (dddd)');
        }

        MomentService.toUserFormatNoTime = toUserFormatNoTime;
        function toUserFormatNoTime(date) {
            return MomentService(date).local().format('MMM DD, YYYY (dddd)');
        }
        
        MomentService.reset = reset;
        function reset() {

        }
        
        MomentService.reset();

        return MomentService;
    }
})();
(function() {
    'use strict';

    angular.module('uabMoment').filter('secondsToDateTime', secondsToDateTime);

    function secondsToDateTime() {
        return function (seconds) {
            var d = new Date(0, 0, 0, 0, 0, 0, 0);

            d.setSeconds(seconds);

            return d;
        };
    }
})();
(function() {
    'use strict';

    angular.module('uabMoment').directive(
        'testMoment',
        testMoment
    );

    function testMoment() {
        return {
            controller:   'MomentController',
            controllerAs: 'ctrl',
            restrict:     'E',
            template:'<div>{{ 1234 | secondsToDateTime | date:\'HH:mm:ss\' }}</div><div>{{ ctrl }}</div>'
        };
    }
})();
